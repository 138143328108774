<template>
    <div>
        <el-row :gutter="10" style="text-align: center">
            <el-col :span="8" :xs="8">
                <div class="statistic-card">
                    <el-statistic :value="0">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                家庭数
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="8" :xs="8">
                <div class="statistic-card">
                    <el-statistic :value="0">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                用户数
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="8" :xs="8">
                <div class="statistic-card">
                    <el-statistic :value="0">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                宠物数
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="8" :xs="8">
                <div class="statistic-card">
                    <el-statistic :value="0">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                商品数
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="8" :xs="8">
                <div class="statistic-card">
                    <el-statistic :value="0">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                订单数
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="8" :xs="8">
                <div class="statistic-card">
                    <el-statistic :value="0">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                订单额
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped>
:global(h2#card-usage ~ .example .example-showcase) {
    background-color: var(--el-fill-color) !important;
}

.el-statistic {
    --el-statistic-content-font-size: 28px;
}

.statistic-card {
    height: 100%;
    padding: 20px;
    border-radius: 4px;
    background-color: var(--el-bg-color-overlay);
}

.statistic-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;
    color: var(--el-text-color-regular);
    margin-top: 16px;
}

.statistic-footer .footer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.statistic-footer .footer-item span:last-child {
    display: inline-flex;
    align-items: center;
    margin-left: 4px;
}

.green {
    color: var(--el-color-success);
}
.red {
    color: var(--el-color-error);
}
</style>
